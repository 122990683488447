/* CSS Reset */


@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Source+Serif+Pro:wght@400;600;700&display=swap');


*,
*::before,
*::after {
	box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
picture,
ul {
	margin: 0;
	padding: 0;
}

ul {
    list-style: none;
}

h1, h2, h3 {
    font-family: 'Source Serif Pro', serif;
    font-weight: 600;
    line-height: 2rem;
    margin-bottom: 1rem;

}

h1 {
    font-size: 2.5rem;
    line-height: 1.75rem;
    font-size: clamp(1.5rem, 5vw, 2.5rem);
}

h3 {
    margin-bottom: 1rem;
}

p {
    margin-bottom: 1rem;
    line-height: 25px;
    font-weight: 500;
}

a {
	text-decoration: none;
    color: inherit;
}

/* span {
    font-weight: 500;
} */

strong {
    font-weight: 700;
}

li {
    list-style: none;
}

body {
    
	line-height: 1.75;
	/* min-height: 100vh; */
    /* max-height: 100%; */
    height: 100vh;
    font-family: 'Quicksand', sans-serif;
	font-size: var(--fs-body);
    background-color: rgb(185, 185, 185);
    overflow-x: hidden;
}

main {
    min-height: 80vh;
}

img,
picture {
	display: block;
}

input,
button,
textarea,
select {
	font: inherit;
}

@media(prefers-reduced-motion: reduce) {
	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1ms !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* End CSS Reset */

/* Custom Props */

:root {
    --hue: 220;

}

/* Utility Classes */

    /* General Utility Classes */

        .flex {
            display: flex;
            gap: var(--gap, 1rem);
        }

        .grid {
            display: grid;
            gap: var(--gap, 1rem);
        }

        .grid-areas {
            grid-template-columns: 1fr 62.5rem 1fr;
            grid-template-areas: "g main r";
        }

        /* border image */

        .wrapper {
            position: relative;
            margin-inline: auto;
            padding-inline: 27px;
            max-width: 62.5rem; /* Or Custom Prop? */
            width: 100%;
            background-color: black;
            min-height: 100vh;

            /* display: flex; */
            
            /* border-image: url(/images/rand.svg); */

            background-image: url(/images/rand.svg), url(/images/rand.svg);
            background-repeat: repeat-y, repeat-y;
            background-position: left, right; 

            border-image-width: 0 28px 0 28px;
            border-image-outset: 20px;

            transition: 250ms;

            will-change: transform;


        }

        .left {
            display: grid;
            grid-template-rows: fit-content();
            padding: 0 1rem 0 0;

        }

        .right {
            display: grid;
            grid-template-rows: fit-content(100%);
            padding: 0 0 0 1rem;
     
        }

        .e-mail-link {
            color: blue;
            font-weight: 400!important;
        }

        .moblie-menu-active-wrapper {
            transform: translateX(276px);
            transition: 250ms;

            /* animation: animation 150ms ease-in-out; */
        }

        @keyframes wrapper {
            /* start */
            0% {
                /* top: -5px; */
                transform: translateY(0px);
            }
            /* end */
            100% {
                /* top: 0; */
                transform: translateY(200px);
            }
        }

        .container {
            margin-inline: auto;
            padding-inline: 1rem;
            max-width: 80rem; 
        }

        .flow > *:where(:not(:last-child)) {
            margin-bottom: var(--flow-space, .5rem);
        }

    /* End General Utility Classes */

    /* Screenreader Only Utility Classes  */

        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border-width: 0;
        }

    /* End Screenreader Only Utility Classes  */

    /* Colors */

    /* End Colors */

    /* Font Specifics */

    /* End Font Sizes */

    .heading {
        font-size: 2rem;
    }





    /* Movie Card */

    .movie-card {
        box-shadow: 1px 2px 4px rgb(170, 170, 170);
        border-radius: 14px;
        padding: 2rem 1rem;
    }



    [type=search] {
        /* -webkit-appearance: none; */
        background-color: rgb(255, 255, 255);
        border: 1px solid gainsboro;
        border-radius: 4px;
        padding: .25rem .5rem;
        width: 100%;
    }
/* 
    [type=search]::placeholder,
    [type=search]::webkit-input-placeholder {
        font-size: 1.75rem;
        color: black;
    } */

    [type=search]:focus {
        outline-color: rosybrown;
    }

    [type=search]:focus {
        outline-color: rosybrown;
    }

    [type=select]:focus {
        outline-color: rosybrown;
    }

    select {
        background-color: rgb(255, 255, 255);
        border: 1px solid gainsboro;
        border-radius: 4px;
        padding: .5rem;
        width: 200px;

    }

    .interface-button {
        background-color: rgb(255, 255, 255);
        border: 1px solid gainsboro;
        border-radius: 4px;
        padding: .25rem .5rem;
        cursor: pointer;
    }

    .interface-button:hover {
        background-color:  gainsboro;
    }


    /* Header Styles */

    .header-container {
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 1rem;
        background-color: white;
        border-bottom-left-radius: .75rem;
        border-bottom-right-radius: .75rem;
        position: sticky;
        top: 0;
    }

    .logotype {
        font-family: 'Source Serif Pro', serif;
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        font-weight: 700;
        line-height: 1.75rem;
        color:rgb(82, 146, 76);
    }

    /* Nav */

    .flag {
        font-size: 2rem;
    }

    

    /* Mobile Menu */

    .mobile-menu-btn {
        cursor: pointer;
        display: block;
        border: none;
        background: inherit;
        padding: .5rem;
        /* width: 3rem; */
        margin-right: .5rem;
    }

    .mobile-menu-btn:hover {
        background: rgb(241, 244, 245);
        border-radius: .5rem;
       }

    .mobile-menu {
        display: block;
        background-color: black;
        color: white;
        width: 300px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        transform: translateX(-100%);
        transition: 250ms;
    }

    .moblie-menu-active-menu {
        transition: 250ms;
        transform: translateX(0%);
    }

    .mobile-nav-item {
        display: flex;
        padding: .5rem 1rem;
        /* font-size: .75rem; */
    }

    .fake-mobile-nav-item {
        display: flex;
        padding: .5rem 1rem;
        width: 292px;
        padding-right: 30px
    }

    .mobile-nav-item:hover {
        background-color: fff;
        color: black;
    }

    /* .fake-mobile-nav-item:hover {
        background-color: fff;
        color: black;
    } */

    /* .fake-mobile-nav-item:hover > i > svg > path {
        fill: #000;
    } */

    .mobile-nav-item-active {
        background-color: fff;
        color: black;
    }

    .accordion-content {
        margin-left: 2rem;
        background-color: black;
        visibility: hidden;
        height: 0;
        transition: 250ms;
    }

    .accordion-expanded {
        visibility: visible;
        height: 58px;
    }

    .rotate {
        transform: rotate(-180deg);
    }

    /* Main Nav */

    .main-nav {
        display: none;
        font-weight: 600;
        position: sticky;
    }

    .nav-item {
        transition: .5s;
        padding: .25rem .5rem;
        border-radius: 8px;
    }

    .mobile-nav-item:hover {
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
    }

    .nav-item:hover {
        /* color: rgb(254, 59, 72); */
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        border-radius: 8px;
        padding: .25rem .5rem;
    }

    .active {
        background-color: white;
        color: black;
        border-radius: 8px;
        padding: .25rem .5rem;
    }

    .mobile-active {
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);

        padding: .5rem  1rem;
    }

    .dropdown {
        position: relative; 
    }

    .dropdown ul {
        color: white;
        position: absolute;
        padding: .25rem;
        width: 100%;
        background-color: black;
        box-shadow: 1px 2px 4px grey;
        border-radius: .75rem;
        /* transition: 1s; */
        visibility: hidden;
    }

    .dropdown:hover ul {
        visibility: visible;
        animation: animation 150ms ease-in-out;
    }

    .dropdown-item {
        padding: .25rem 1rem;
        display: block;
        width: 100%;
    }

    .dropdown-title {
        padding: .25rem .5rem;
    }

    .dropdown-item:hover {
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        padding: .25rem 1rem;
    }

    @keyframes animation {
        0% {
            transform: translateY(-10px);
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    /* Main */

    .main {
        background-color: white;
        border-radius: .75rem;
        padding: 3rem 1rem;
    }

    .movie-count {
        text-decoration: underline;
        font-weight: bold;
    }

    .movies-wrapper {
        margin-top: 2rem;
    }

    .movie-container {
        margin-bottom: 3.5rem;
    }

    /* Cards */

    .list-item {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    .image-wrapper {
        flex:0 0 150px;
    }

    .info-container {
        flex: 1 1 400px;
    }

    .item-header {
        background-color: #D0E2DA;
        border-radius: 1rem;
        padding: .5rem 1rem;
    }

    .item-image {
        border-radius: 1rem;
        width: 150px;
    }

    .actors-container {
        margin: 1rem 0;
    }

    .strong-paragraph {
        font-weight: 700;
    }
/* 
    .paragraph {
        
    } */

    /* Pagination */

    .btn-active {
        background-color: rgb(214, 78, 24)!important;
    }

    .page-btn {
        cursor: pointer;
        border: none;
        padding: 1rem;
        background-color:rgb(245, 245, 245);
        font-weight: 600;
    }

    .page-btn:hover {
        background-color:rgb(176, 236, 194);
    }

    .label-btn {
        cursor: pointer;
        border: none;
        padding: 1rem;
        background-color:rgb(245, 245, 245);
        font-weight: 600;
    }

    .label-btn:hover {
        background-color:rgb(176, 236, 194);
    }

    /* Footer */

    .footer {
        min-height: 100px;
        margin-top: 55px;
        padding: 1rem;
        background-color: white;
        border-top-left-radius: .75rem;
        border-top-right-radius: .75rem;
    }

    .pagination-container {
        display: flex;
        flex-wrap: wrap;
    }

    .start {
        order: 1;
    }

    .back {
        order: 2;
    }

    .next {
        order: 4;
    }

    .end {
        order: 5;
    }

    .page-numbers {
        order: 3;
    }

    .scrollButton {
        /* position: fixed;
        bottom: 0;
        right: 0; */
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 32px;
        height: 32px;
        background: white;
    }

    .scrollButton:hover {
        background-color:  gainsboro;
    }

    .scrollButton > svg {
        width: 24px;
        height: 24px;
    }


.message-wrapper {
    padding: 1rem;
    border-radius: .75rem;
    background-color: #d0e2da;
}

.message-wrapper > h2 {
    margin-bottom: 0;
}


     @media(max-width: 320px) {

        .moblie-menu-active-wrapper {
            transform: translateX(200px);
         }

        .fake-mobile-nav-item {
            width: 216px;
        }

        .accordion-content > li > a {
            font-size: .75rem;
        }

        .accordion-label {
            font-size: .75rem;
        }

        .mobile-nav-item {
            font-size: .75rem;
        }

    }


    @media(min-width: 768px) {

        /* .wrapper {
            padding-inline: 27px;
        } */

        .header-container {
            position: relative;
        }

        .main {
            padding: 3rem 1.5rem;
        }

        .logotype {
            font-size: 2rem;
        }

        .mobile-menu {
            display: none;
        }

        .mobile-menu-btn {
            display: none;
        }

        .main-nav {
            display: flex;
            gap: 1rem;
        }

        .sticky-nav {
            position: sticky;
            top: 0;
            width: 100%;
            background-color: black;
        }
    }

