@import "https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Source+Serif+Pro:wght@400;600;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p, figure, picture, ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

h1, h2, h3 {
  margin-bottom: 1rem;
  font-family: Source Serif Pro, serif;
  font-weight: 600;
  line-height: 2rem;
}

h1 {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  line-height: 1.75rem;
}

h3 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 25px;
}

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: 700;
}

li {
  list-style: none;
}

body {
  height: 100vh;
  font-family: Quicksand, sans-serif;
  line-height: 1.75;
  font-size: var(--fs-body);
  background-color: #b9b9b9;
  overflow-x: hidden;
}

main {
  min-height: 80vh;
}

img, picture {
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    animation-duration: .01ms !important;
    animation-iteration-count: 1ms !important;
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
  }
}

:root {
  --hue: 220;
}

.flex {
  gap: var(--gap, 1rem);
  display: flex;
}

.grid {
  gap: var(--gap, 1rem);
  display: grid;
}

.grid-areas {
  grid-template-columns: 1fr 62.5rem 1fr;
  grid-template-areas: "g main r";
}

.wrapper {
  max-width: 62.5rem;
  width: 100%;
  min-height: 100vh;
  will-change: transform;
  background-color: #000;
  background-image: url("rand.6c56fc9b.svg"), url("rand.6c56fc9b.svg");
  background-position: 0, 100%;
  background-repeat: repeat-y, repeat-y;
  border-image-width: 0 28px;
  border-image-outset: 20px;
  margin-inline: auto;
  padding-inline: 27px;
  transition: all .25s;
  position: relative;
}

.left {
  grid-template-rows: fit-content();
  padding: 0 1rem 0 0;
  display: grid;
}

.right {
  grid-template-rows: fit-content(100%);
  padding: 0 0 0 1rem;
  display: grid;
}

.e-mail-link {
  color: #00f;
  font-weight: 400 !important;
}

.moblie-menu-active-wrapper {
  transition: all .25s;
  transform: translateX(276px);
}

@keyframes wrapper {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(200px);
  }
}

.container {
  max-width: 80rem;
  margin-inline: auto;
  padding-inline: 1rem;
}

.flow > :where(:not(:last-child)) {
  margin-bottom: var(--flow-space, .5rem);
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.heading {
  font-size: 2rem;
}

.movie-card {
  border-radius: 14px;
  padding: 2rem 1rem;
  box-shadow: 1px 2px 4px #aaa;
}

[type="search"] {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: .25rem .5rem;
}

[type="search"]:focus, [type="select"]:focus {
  outline-color: #bc8f8f;
}

select {
  width: 200px;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: .5rem;
}

.interface-button {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: .25rem .5rem;
}

.interface-button:hover {
  background-color: #dcdcdc;
}

.header-container {
  height: 100px;
  width: 100%;
  background-color: #fff;
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  display: flex;
  position: sticky;
  top: 0;
}

.logotype {
  color: #52924c;
  font-family: Source Serif Pro, serif;
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  font-weight: 700;
  line-height: 1.75rem;
}

.flag {
  font-size: 2rem;
}

.mobile-menu-btn {
  cursor: pointer;
  background: inherit;
  border: none;
  margin-right: .5rem;
  padding: .5rem;
  display: block;
}

.mobile-menu-btn:hover {
  background: #f1f4f5;
  border-radius: .5rem;
}

.mobile-menu {
  color: #fff;
  width: 300px;
  background-color: #000;
  transition: all .25s;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
}

.moblie-menu-active-menu {
  transition: all .25s;
  transform: translateX(0%);
}

.mobile-nav-item {
  padding: .5rem 1rem;
  display: flex;
}

.fake-mobile-nav-item {
  width: 292px;
  padding: .5rem 1rem;
  padding-right: 30px;
  display: flex;
}

.mobile-nav-item:hover, .mobile-nav-item-active {
  background-color: fff;
  color: #000;
}

.accordion-content {
  visibility: hidden;
  height: 0;
  background-color: #000;
  margin-left: 2rem;
  transition: all .25s;
}

.accordion-expanded {
  visibility: visible;
  height: 58px;
}

.rotate {
  transform: rotate(-180deg);
}

.main-nav {
  font-weight: 600;
  display: none;
  position: sticky;
}

.nav-item {
  border-radius: 8px;
  padding: .25rem .5rem;
  transition: all .5s;
}

.mobile-nav-item:hover {
  color: #000;
  background-color: #fff;
}

.nav-item:hover, .active {
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  padding: .25rem .5rem;
}

.mobile-active {
  color: #000;
  background-color: #fff;
  padding: .5rem 1rem;
}

.dropdown {
  position: relative;
}

.dropdown ul {
  color: #fff;
  width: 100%;
  visibility: hidden;
  background-color: #000;
  border-radius: .75rem;
  padding: .25rem;
  position: absolute;
  box-shadow: 1px 2px 4px gray;
}

.dropdown:hover ul {
  visibility: visible;
  animation: .15s ease-in-out animation;
}

.dropdown-item {
  width: 100%;
  padding: .25rem 1rem;
  display: block;
}

.dropdown-title {
  padding: .25rem .5rem;
}

.dropdown-item:hover {
  background-color: #fff;
  border-radius: 8px;
  padding: .25rem 1rem;
}

@keyframes animation {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.main {
  background-color: #fff;
  border-radius: .75rem;
  padding: 3rem 1rem;
}

.movie-count {
  font-weight: bold;
  text-decoration: underline;
}

.movies-wrapper {
  margin-top: 2rem;
}

.movie-container {
  margin-bottom: 3.5rem;
}

.list-item {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.image-wrapper {
  flex: 0 0 150px;
}

.info-container {
  flex: 400px;
}

.item-header {
  background-color: #d0e2da;
  border-radius: 1rem;
  padding: .5rem 1rem;
}

.item-image {
  width: 150px;
  border-radius: 1rem;
}

.actors-container {
  margin: 1rem 0;
}

.strong-paragraph {
  font-weight: 700;
}

.btn-active {
  background-color: #d64e18 !important;
}

.page-btn {
  cursor: pointer;
  background-color: #f5f5f5;
  border: none;
  padding: 1rem;
  font-weight: 600;
}

.page-btn:hover {
  background-color: #b0ecc2;
}

.label-btn {
  cursor: pointer;
  background-color: #f5f5f5;
  border: none;
  padding: 1rem;
  font-weight: 600;
}

.label-btn:hover {
  background-color: #b0ecc2;
}

.footer {
  min-height: 100px;
  background-color: #fff;
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
  margin-top: 55px;
  padding: 1rem;
}

.pagination-container {
  flex-wrap: wrap;
  display: flex;
}

.start {
  order: 1;
}

.back {
  order: 2;
}

.next {
  order: 4;
}

.end {
  order: 5;
}

.page-numbers {
  order: 3;
}

.scrollButton {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: #fff;
  align-items: center;
  display: flex;
}

.scrollButton:hover {
  background-color: #dcdcdc;
}

.scrollButton > svg {
  width: 24px;
  height: 24px;
}

.message-wrapper {
  background-color: #d0e2da;
  border-radius: .75rem;
  padding: 1rem;
}

.message-wrapper > h2 {
  margin-bottom: 0;
}

@media (max-width: 320px) {
  .moblie-menu-active-wrapper {
    transform: translateX(200px);
  }

  .fake-mobile-nav-item {
    width: 216px;
  }

  .accordion-content > li > a, .accordion-label, .mobile-nav-item {
    font-size: .75rem;
  }
}

@media (min-width: 768px) {
  .header-container {
    position: relative;
  }

  .main {
    padding: 3rem 1.5rem;
  }

  .logotype {
    font-size: 2rem;
  }

  .mobile-menu, .mobile-menu-btn {
    display: none;
  }

  .main-nav {
    gap: 1rem;
    display: flex;
  }

  .sticky-nav {
    width: 100%;
    background-color: #000;
    position: sticky;
    top: 0;
  }
}

/*# sourceMappingURL=über-uns.d0987e19.css.map */
